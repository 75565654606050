import React, { useState, useEffect } from "react";
import { authRequest } from "../../utils/http";
import { Create } from "@mui/icons-material";

const AdminAdminUsers = () => {
    const [adminUsers, setAdminUsers] = useState([]);

    useEffect(() => {
        const fetchAdminUsers = async () => {
            try {
                const res = await authRequest("/admin-users", "GET");
                if (res.status === 200) {
                    setAdminUsers(res.data);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchAdminUsers();
    }, []);


    return (
        <>
            <h2>Manage Admin Users</h2>
            {adminUsers.map((adminUser) => (
                <AdminUser key={adminUser.id} adminUser={adminUser} setAdminUsers={setAdminUsers} />
            ))}
            <CreateAdminUser setAdminUsers={setAdminUsers} />
        </>
    );
};

export default AdminAdminUsers;

const AdminUser = ({ adminUser, setAdminUsers }) => {
    const [deleteMode, setDeleteMode] = useState(false);
    const { id, email } = adminUser;

    const handleDelete = async () => {
        try {
            const res = await authRequest(`/admin-users`, "DELETE", {
                id: id
            });
            if (res.status === 200) {
                setAdminUsers(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div
            style={{
                backgroundColor: `${deleteMode ? "lightcoral" : ""}`,
                width: "100%",
                marginBottom: "20px",
                padding: "20px",
                border: "3px solid black",
                borderRadius: "5px",
            }}
        >
            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <p>{email}</p>
                {!deleteMode && (
                    <button style={{ marginBottom: "20px" }} onClick={() => setDeleteMode(true)}>
                        Delete
                    </button>
                )}
            </div>
            <div>
                {deleteMode && (
                    <>
                        <p style={{ marginTop: "20px", marginBottom: "20px", color: "white" }}>
                            Are you sure you want to delete this admin user? They will no longer be able to access the admin area.
                        </p>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <button onClick={() => handleDelete()}>
                                {`Delete ${email}`}
                            </button>
                            <button onClick={() => setDeleteMode(false)}>
                                Cancel
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const CreateAdminUser = ({ setAdminUsers }) => {
    const [email, setEmail] = useState("");

    const handleCreate = async () => {
        try {
            const res = await authRequest("/admin-users", "POST", {
                email
            });
            if (res.status === 200) {
                setEmail("");
                setAdminUsers(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div
            style={{
                width: "100%",
                marginBottom: "20px",
                padding: "20px",
                border: "3px solid black",
                borderRadius: "5px",
            }}
        >
            <h3 style={{ fontWeight: 900, marginBottom: "20px" }}>Create Admin User</h3>
            <p>New users will be able to view, change and delete all content and data!</p>
            <p>Please ask any new users you create to use the "Forgot Password" link on the login page to set their password.</p>
            <div>
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                    <label style={{ marginRight: "20px", marginBottom: "10px" }} htmlFor="email">Email</label>
                    <input
                        style={{ minWidth: "300px" }}
                        type="text"
                        name="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </div>
                <button onClick={() => handleCreate()}>
                    Create
                </button>
            </div>
        </div>
    );
}