import React, { useContext, useEffect, useState } from "react";
import { authRequest } from "../../utils/http";
import { PagesContext } from "../../App";

const AdminBookingsSettings = () => {
  return (
    <>
      <h1>Admin Settings</h1>
      <AdministerTradingSlots />
      <AdministerEmailReceivers />
      <AdministerShowBookingsPage />
    </>
  );
};

const AdministerTradingSlots = () => {
  const daysOfTheWeek = ["Sun", "Mon", "Tues", "Weds", "Thurs", "Fri", "Sat"];
  const [tradingSlots, setTradingSlots] = useState([]);

  const [tradingSlotLocation, setTradingSlotLocation] = useState("");
  const [tradingSlotAddress, setTradingSlotAddress] = useState("");
  const [tradingSlotDayOfWeek, setTradingSlotDayOfWeek] = useState(0);
  const [tradingSlotStartTime, setTradingSlotStartTime] = useState("");
  const [tradingSlotEndTime, setTradingSlotEndTime] = useState("");
  const [maxBookings, setMaxBookings] = useState(1);

  useEffect(() => {
    const getTradingSlots = async () => {
      try {
        const res = await authRequest("/trading-slots", "GET");
        const { status, data } = res;
        if (status === 200) {
          if (data) {
            setTradingSlots(data);
          }
        } else {
          alert("An error occured. Please check your internet connection.");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getTradingSlots();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const body = {
      tradingSlotLocation,
      tradingSlotAddress,
      tradingSlotDayOfWeek,
      tradingSlotStartTime,
      tradingSlotEndTime,
      maxBookings,
    };
    try {
      const res = await authRequest("/trading-slots/create", "POST", body);
      const { status } = res;
      if (status === 200) {
        window.location.reload();
      } else {
        alert("An error occured. Please check your internet connection.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (event, tradingSlotId) => {
    event.preventDefault();
    const body = {
      id: tradingSlotId,
    };
    try {
      const res = await authRequest("/trading-slots/archive", "POST", body);
      const { status } = res;
      if (status === 200) {
        window.location.reload();
      } else {
        alert("An error occured. Please check your internet connection.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <h2>Create a trading slot</h2>
        <form className="create-slot-form" onSubmit={(event) => handleSubmit(event)}>
          <input
            type="text"
            name="locationName"
            value={tradingSlotLocation}
            onChange={(event) => setTradingSlotLocation(event.target.value)}
            placeholder="Trading Location Name"
            required={true}
          />
          <input
            type="text"
            name="locationName"
            value={tradingSlotAddress}
            onChange={(event) => setTradingSlotAddress(event.target.value)}
            placeholder="Address"
            required={true}
          />
          <input
            type="text"
            name="startTime"
            value={tradingSlotStartTime}
            onChange={(event) => setTradingSlotStartTime(event.target.value)}
            placeholder="Start Time e.g. 9am"
            required={true}
          />
          <input
            type="text"
            name="endTime"
            value={tradingSlotEndTime}
            onChange={(event) => setTradingSlotEndTime(event.target.value)}
            placeholder="End Time e.g. 5pm"
            required={true}
          />
          <select
            name="dayOfTheWeek"
            id=""
            onChange={(event) => setTradingSlotDayOfWeek(event.target.value)}
            required={true}
          >
            {daysOfTheWeek.map((element, index) => (
              <option key={index} value={index}>
                {element}
              </option>
            ))}
          </select>
          <input
            type="number"
            placeholder="Max bookings"
            id="maxBookings"
            onChange={(event) => setMaxBookings(event.target.value)}
            name="maxBookings"
            min="1"
            required={true}
          ></input>
          <input type="submit" value="Create" />
        </form>
      </div>
      <h2 style={{ marginTop: "20px" }}>Live trading slots</h2>
      <ul>
        {tradingSlots.sort(function (a, b) {
          return new Date(a.dayOfWeekInteger) - new Date(b.dayOfWeekInteger);
        }).map((slot, index) => {
          return <TradingSlotControl key={index} handleDelete={handleDelete} slot={slot} daysOfTheWeek={daysOfTheWeek} />;
        })}
      </ul>
    </>
  );
};

const TradingSlotControl = ({ handleDelete, slot, daysOfTheWeek }) => {
  const [isDeleteWarn, setIsDeleteWarn] = useState(false);
  const {
    id,
    endTime,
    locationAddress,
    locationName,
    startTime,
    dayOfWeekInteger,
    maxBookings,
  } = slot;

  return (
    <li style={{ border: `${isDeleteWarn ? "solid 1px red" : ""}` }}>
      <div>{locationName}</div>
      <div>{locationAddress}</div>
      <div>
        {daysOfTheWeek[dayOfWeekInteger]}, {startTime} - {endTime}
      </div>
      <div>{maxBookings} bookable places</div>
      {isDeleteWarn ? <><div style={{ color: "orange" }}>Are you sure you want to delete this trading slot? Applications to trade in this slot that have already been made will not be deleted, but no new applications to trade in this slot will be able to be made.</div><div>To cancel, please refresh the page.</div><button onClick={(event) => handleDelete(event, id)}>Confirm Delete</button></> :
        <button onClick={() => setIsDeleteWarn(true)}>
          Delete
        </button>
      }
    </li >
  )
};

const AdministerEmailReceivers = () => {
  const [emailReceivers, setEmailReceivers] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const getEmailReceivers = async () => {
      try {
        const res = await authRequest("/approval-email-receivers", "GET");
        const { status, data } = res;
        if (status === 200) {
          if (data) {
            setEmailReceivers(data);
          }
        } else {
          setMessage(data);
        }

      } catch (error) {
        console.log(error);
      }
    }
    getEmailReceivers();
  }, []);

  const handleCreate = async (event) => {
    event.preventDefault();
    const body = {
      email: email,
    };
    try {
      const res = await authRequest("/approval-email-receivers/create", "POST", body);
      const { status, data } = res;
      if (status === 200) {
        window.location.reload();
      } else {
        setMessage(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <h2 style={{ marginBottom: "10px" }}>Approval Email Receivers</h2>
      <div>{emailReceivers.map(receiver => {
        const { email } = receiver;
        return <EmailReceiver key={email} email={email} />
      })}</div>
      <form style={{ marginBottom: "50px" }} onSubmit={(event) => handleCreate(event)}>
        <input onChange={(event) => setEmail(event.target.value)} type="email" name="email" placeholder="Email address" required={true} />
        <input type="submit" value="Create" />
      </form>
      <p>{message}</p>
    </>
  );
}

const EmailReceiver = ({ email }) => {
  const [deleteWarn, setDeleteWarn] = useState(false);

  const handleDelete = async (event, email) => {
    event.preventDefault();
    const body = {
      email: email,
    };
    try {
      const res = await authRequest("/approval-email-receivers/delete", "POST", body);
      const { status, data } = res;
      if (status === 200) {
        window.location.reload();
      } else {
        alert(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ display: "flex", marginBottom: "10px", justifyContent: 'space-between', width: "80%", maxWidth: "400px" }}>{deleteWarn ? <div style={{ color: 'orange' }}><div>{`Are you sure you want to delete ${email}`}</div><button onClick={(event) => handleDelete(event, email)}>Delete</button></div> : <><div>{email}</div><button onClick={() => setDeleteWarn(true)} style={{}}>X</button></>}</div>
  );
}

const AdministerShowBookingsPage = () => {
  const [showBookingPage, setShowBookingPage] = useState(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);
  const pages = useContext(PagesContext);

  useEffect(() => {
    const bookingPageIsShowing = pages.some((element) => element.id === "bookings-page");
    setShowBookingPage(bookingPageIsShowing);
  }, [pages]);

  const handleChange = async (event) => {
    event.preventDefault();
    setCheckboxDisabled(true);
    const res = await authRequest("/pages/show-booking-page", "POST", { showBookingPage: !showBookingPage });
    const { status } = res;
    if (status === 200) {
      window.location.reload();
    } else {
      alert("An error occured. Please check your internet connection.");
    }
  };

  return (
    <>
      <h2>Show Booking Page</h2>
      <div style={{ display: "flex", marginBottom: "10px", justifyContent: 'space-between', width: "80%", maxWidth: "400px" }}>
        <div>Check or uncheck the box to show or hide the booking page:</div>
        <div>
          <input type="checkbox" disabled={checkboxDisabled} checked={showBookingPage} onChange={handleChange} />
        </div>
      </div>
    </>
  )
}

export default AdminBookingsSettings;
