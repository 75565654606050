export const openRequest = (path, method, body, asText) => {
  const url =
    process.env.NODE_ENV === "production"
      ? window.location.protocol
        .concat("//")
        .concat(window.location.host)
        .concat("/api")
      : "http://localhost:4000/api";

  // : "https://ourlittlemarkets.co.uk/api"; // just to allow testing styles and locally on the real data

  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  return fetch(url + path, options)
    .then(async (res) => {
      const result = {};
      result.status = res.status;
      result.data = await res.json();
      return result;
    })
    .catch((err) => console.log("Error:", err));
};

export const authRequest = (path, method, body, asText) => {
  const url =
    process.env.NODE_ENV === "production"
      ? window.location.protocol
        .concat("//")
        .concat(window.location.host)
        .concat("/api")
      : "http://localhost:4000/api";

  const options = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    credentials: "include",
    body: JSON.stringify(body),
  };

  return fetch(url + path, options)
    .then(async (res) => {
      const result = {};
      result.status = res.status;
      result.data = asText ? await res.text() : await res.json();

      if (res.status === 401) {
        localStorage.removeItem("token");
        window.location.assign("/login");
        console.log("REDIRECTED BY http.js");
      }

      if (res.status === 402) {
        window.location.assign(`/payment-required`);
        console.log("REDIRECTED BY http.js");
      }

      return result;
    })
    .catch((err) => console.log("Error:", err));
};
