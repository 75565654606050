import React, { useState } from "react";
import { authRequest } from "../../../utils/http";

const BookingControl = ({ adminUserStatus, id, convertStatusToUserStatus, booking, setBookings }) => {
    const [bookingStatus, setBookingStatus] = useState(adminUserStatus);
    const [newStatus, setNewStatus] = useState(booking.status);
    const [approvalEmailSent,] = useState(booking.approvalEmailSent);
    const [bookingNotes, setBookingNotes] = useState(booking.bookingNotes || "");
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState("");
    const { approvalDeadlinePassed } = booking;

    const handleStatusChange = async (e) => {
        setNewStatus(e.target.value);
    };

    const resetStatus = () => {
        setNewStatus(booking.status);
    }

    const onNotesChange = (e) => {
        setBookingNotes(e.target.value);
    };

    const onSave = async (id) => {
        setLoading(true);
        const { status: httpStatus, data } = await authRequest(`/bookings/`, "PUT", {
            newStatus,
            bookingNotes,
            id,
        });
        if (httpStatus === 200) {
            setBookingStatus(convertStatusToUserStatus(data.status));
            setLoading(false);
            setEditMode(false);
            // This is just for NeedApproval  - so that it updates the number of bookings that need approval
            if (setBookings) {
                setBookings((prev) => {
                    const updatedBookings = prev.map((booking) => {
                        if (booking.id === id) {
                            return { ...booking, status: data.status };
                        }
                        return booking;
                    });
                    return updatedBookings;
                });
            }
        } else if (httpStatus === 400) {
            setMessage(data.message);
        } else {
            console.error("An error has occurred");
            setIsError(true);
        }
    };

    return (
        <>
            {isError ? (
                <div>An error occurred, please refresh the page</div>
            ) : (
                <div
                    className="booking-control"
                    style={{
                        backgroundColor: bookingStatus.color,
                    }}
                >
                    {loading ? <div>Loading...</div> :
                        <>
                            <p>
                                <span>Status: </span>
                                <span style={{ textDecoration: "underline" }}>
                                    {bookingStatus.text}
                                </span>
                            </p>
                            {approvalDeadlinePassed ? <div>Approval deadline has passed</div> : <>
                                {
                                    editMode ? (
                                        <div style={{ display: "flex" }} >
                                            <div>
                                                <input
                                                    type="radio"
                                                    value="verified"
                                                    checked={newStatus === "verified"}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="regular">Pending</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    value="approved"
                                                    checked={newStatus === "approved"}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="medium">Approved</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    value="denied"
                                                    checked={newStatus === "denied"}
                                                    onChange={handleStatusChange}
                                                />
                                                <label htmlFor="large">Denied</label>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                            </>}
                            <div>
                                <span>Notes: </span>
                                {editMode ? <textarea style={{ display: "block" }} value={bookingNotes} onChange={onNotesChange} placeholder="Add notes" /> : <div>{bookingNotes}</div>}
                            </div>
                            <div>
                                <button
                                    style={{ float: "right" }}
                                    onClick={(e) => { setEditMode((prev) => !prev); resetStatus(); }}
                                >
                                    {editMode ? "Back" : "Edit"}
                                </button>
                                {editMode ? <button onClick={() => onSave(id)}>Save</button> : ""}
                            </div>
                            <p>{approvalEmailSent ? "Approval email has been sent." : ""}</p>
                        </>
                    }
                    <div style={{ color: 'red' }}>{message}</div>
                </div>
            )
            }
        </>
    );
};

export default BookingControl;