import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { authRequest } from "../utils/http";
import Loading from "../elements/Loading";

const Verify = (props) => {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({});

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    const fetchVerify = async () => {
      try {
        const res = await authRequest(`/bookings/verify/${token}`, "POST");
        setLoading(false);
        if (res.status >= 400 && res.status < 600) {
          setMessage(res.data.message);
        } else if (res.status === 200) {
          setBookingDetails(res.data);
          setSuccess(true);
        }
      } catch (e) {
        console.log("Error fetching data");
      }
    };
    fetchVerify();
  }, [token]);

  console.log(success);

  return (
    <div className="public-wrapper">
      <div className="booking-form">
        {loading ? (
          <Loading />
        ) : (
          <VerificationResult success={success} message={message} bookingDetails={bookingDetails} />
        )}
      </div>
    </div>
  );
};

const VerificationResult = ({ success, message, bookingDetails }) => {
  const { formattedDay, locationName, locationAddress, startTime, endTime } = bookingDetails;
  return success ? (
    <div>
      <h2>Your Booking Has Been Made</h2>
      <h3 style={{ fontWeight: 600, margin: "20px 0 10px 0" }}>Application Details:</h3>
      <p style={{ margin: "0 0 0 0" }}>{`${formattedDay}`}</p>
      <p style={{ margin: "0 0 0 0" }}>{`${startTime} - ${endTime}`}</p>
      <p style={{ margin: "0 0 0 0" }}>{`${locationName}`}</p>
      <p style={{ margin: "0 0 0 0" }}>{`${locationAddress}`}</p>
      <h2 style={{ marginTop: "20px" }}>You will receive an email, once your booking is approved</h2>
    </div>
  ) : (
    <p className="user-error">{message}</p>
  );
};

export default Verify;
