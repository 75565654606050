import React, { useEffect, useState } from "react";
import { openRequest } from "../../../utils/http";

import TraderExplainer from "./TraderExplainer";
import ConditionsCheck from "./ConditionsCheck";
import FoodLoop from "./FoodLoop";
import AvailableSlots from "./AvailableSlots";
import TraderDetails from "./TraderDetails";
import MustConfirm from "./MustConfirm";

const TraderBookingFlow = (props) => {
  const [step, setStep] = useState(1);
  const [bookingData, setBookingData] = useState({
    slot: {},
    traderDetails: { fullName: "", email: "", phoneNumber: "", postCode: "", commodity: "" },
  });
  const [formRenderables, setFormRenderables] = useState({});

  useEffect(() => {
    const getPageContent = async () => {
      const { status, data } = await openRequest("/bookings-page-content", "GET");
      if (status === 200) {
        setFormRenderables(data);
      } else {
        // API ERROR
      }
    };
    getPageContent();
  }, []);

  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return <TraderExplainer setStep={setStep} allRenderData={formRenderables} />;
      case 2:
        return <ConditionsCheck setStep={setStep} allRenderData={formRenderables} />;
      case 3:
        return (
          <FoodLoop
            setStep={setStep}
            bookingData={bookingData}
            setBookingData={setBookingData}
            allRenderData={formRenderables}
          />
        );
      case 4:
        return (
          <AvailableSlots
            setStep={setStep}
            bookingData={bookingData}
            setBookingData={setBookingData}
            allRenderData={formRenderables}
          />
        );
      case 5:
        return (
          <TraderDetails
            setStep={setStep}
            bookingData={bookingData}
            setBookingData={setBookingData}
            allRenderData={formRenderables}
          />
        );
      case "final":
        return <MustConfirm bookingData={bookingData} allRenderData={formRenderables} />;
      default:
        return "An error occured.";
    }
  };

  return <>{renderSwitch(step)}</>;
};

export default TraderBookingFlow;