import React from "react";
import PageRenderer from "../PageRenderer";

const TraderExplainer = ({ setStep, allRenderData }) => {
    const { traderExplainer } = allRenderData || {};
    const { content } = traderExplainer || {};
    return (
        <>
            <div><PageRenderer renderData={content} /></div>
            <button onClick={() => setStep((prev) => prev + 1)}>Get Started</button>
        </>
    );
};

export default TraderExplainer;