import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { authRequest } from "../utils/http";
import Header from "./PageBlocks/Header";
import Signout from "./PageBlocks/Signout";

const Admin = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const loginCheck = async () => {
      const { status } = await authRequest("/admin-users/check", "POST", {});
      if (status === 200) {
        setLoggedIn(true);
      }
    };
    loginCheck();
  }, []);



  return (
    <>
      <Header isAdmin={true} />
      {loggedIn ? (
        <div className="public-wrapper">
          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "40px" }}>
            {
              location.pathname === '/admin' ? <div></div> : <Link to="/admin"><button className="button-styles-remove">{"<"} Back</button></Link>
            }
            <Signout />
          </div>
          <div className="main-content" style={{ maxWidth: "1000px", margin: "0 auto" }}>
            <Outlet />
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default Admin;
