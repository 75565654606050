import { Link, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { HeaderContext } from "../../App";
import { push as Menu } from "react-burger-menu";
import { PagesContext } from "../../App";
import SocialLinks from "./SocialLinks";

var burgerStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "20px",
    top: "20px",
  },
  bmBurgerBars: {
    background: "white",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    padding: "0.8em",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0)",
  },
};

const Header = ({ isAdmin }) => {
  const isNarrowScreen = window.innerWidth < 1000 ? true : false;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [headerColor, setHeaderColor] = useState("#BF2546");
  const [pageSlug, setPageSlug] = useState("");
  const [burgerStylesDynamic, setBurgerStylesDynamic] = useState(burgerStyles);
  const location = useLocation();

  const pages = useContext(PagesContext);
  const headerLinks = useContext(HeaderContext);

  useEffect(() => {
    const currentSlug = location.pathname.slice(1);
    const page = pages.find((page) => page.pageSlug === currentSlug);
    const pageSlug = page ? page.pageSlug : "";
    setPageSlug(pageSlug);
    page ? setHeaderColor(page.color) : setHeaderColor("#BF2546");
  }, [location, pages]);

  useEffect(() => {
    setBurgerStylesDynamic((prev) => {
      return { ...prev, bmMenu: { ...prev.bmMenu, background: headerColor } };
    });
  }, [headerColor]);

  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="header">
      <div className="header-color" style={{ backgroundColor: headerColor }}>
        <div className="public-wrapper">
          <SocialLinks headerLinks={headerLinks} />
        </div>
      </div>
      <div className="public-wrapper">
        <div
          className="logo-plus-book"
          style={{
            minHeight: "10vh",
            display: "flex",
          }}
        >
          <Link className="logo-link" to={"/"}>
            <img
              src={headerLinks ? headerLinks.logoUrl : ""}
              alt="Our Little Markets Logo"
              style={{ height: "auto", width: "100%" }}
            />
          </Link>
        </div>
      </div>
      {!isAdmin ? (
        <>
          {isNarrowScreen ? (
            <Menu
              isOpen={isMenuOpen}
              onStateChange={(state) => handleStateChange(state)}
              right
              styles={burgerStylesDynamic}
            >
              <MenuLinks
                closeMenu={closeMenu}
                isNarrowScreen={isNarrowScreen}
              />
            </Menu>
          ) : (
            <MenuLinks
              isNarrowScreen={isNarrowScreen}
              headerColor={headerColor}
              currentSlug={pageSlug}
            />
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

const MenuLinks = ({ closeMenu, isNarrowScreen, headerColor, currentSlug }) => {
  const pages = useContext(PagesContext);
  const style = isNarrowScreen
    ? {}
    : {
        display: "flex",
      };

  return (
    <>
      <div className="header-color" style={{ backgroundColor: headerColor }}>
        <div className="public-wrapper">
          <div onClick={closeMenu} style={style}>
            {pages
              .filter(
                (page) =>
                  page.pageType === "standard" ||
                  page.pageType === "booking" ||
                  page.pageType === "list"
              )
              .map((page) => {
                const { id, pageSlug, pageTitle } = page;
                const path = pageSlug === "home" ? "" : `${pageSlug}`;
                return (
                  <div
                    style={{}}
                    className={`public-nav-item ${
                      (currentSlug === "" && pageSlug === "home") ||
                      pageSlug === currentSlug
                        ? "public-nav-item-active"
                        : ""
                    }`}
                    key={id}
                  >
                    <Link to={path}>{pageTitle}</Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
