import React, { useState, useEffect } from "react";

const Loading = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(setShowSpinner(true), 800);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return showSpinner && <p>Loading...</p>;
};

export default Loading;
