import React, { useContext } from "react";
import { FooterContext, HeaderContext } from "../../App";
import StandardPageRenderer from "./PageRenderer";
import SocialLinks from "./SocialLinks";

const Footer = () => {
  const footerContent = useContext(FooterContext);
  const headerLinks = useContext(HeaderContext);

  return (

    <div className="public-wrapper">
      <div className="footer-main">
        <div className="footer-content">
          <StandardPageRenderer renderData={footerContent} />
        </div>
        <div className="social-links-footer">
          <SocialLinks headerLinks={headerLinks} isFooter={true} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
