import React, { useState } from "react";
import { openRequest } from "../../utils/http";

const Signout = (props) => {
  const { isHeaderNav } = props;
  const [signoutSuccess, setSignoutSuccess] = useState(false);

  const handleClick = async (event) => {
    event.preventDefault();
    localStorage.clear();

    try {
      const res = await openRequest("/admin-users/signout", "POST");
      console.log(res.status);
      if (res.status === 200) {
        setSignoutSuccess(true);
        props.clearState();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return signoutSuccess ? (
    window.location.assign("/login")
  ) : (
    <>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <button
          className={`${isHeaderNav ? "header-nav-link" : ""} button-styles-remove`}
          onClick={handleClick}
        >
          Signout
        </button>
      </div>
    </>
  );
};

export default Signout;
