import React, { useState } from "react";
import NeedApproval from "./AdminBookingsComponents/NeedApproval";
import TodaysBookings from "./AdminBookingsComponents/TodaysBookings";
import DownloadBookings from "./AdminBookingsComponents/DownloadBookings";
import WeeklyCalendar from "./AdminBookingsComponents/WeeklyCalendar";

const AdminBookingsManage = () => {
    const [showing, setShowing] = useState("need-approval");
    return (
        <>
            <div>
                <div>
                    <button className="button-styles-remove"
                        onClick={() => setShowing("need-approval")}
                        style={{
                            backgroundColor: `${showing === "need-approval" ? "blue" : ""}`,
                            color: `${showing === "need-approval" ? "white" : ""}`,
                        }}
                    >
                        Need Approval
                    </button>
                    <button className="button-styles-remove"
                        onClick={() => setShowing("weekly")}
                        style={{
                            backgroundColor: `${showing === "weekly" ? "blue" : ""}`,
                            color: `${showing === "weekly" ? "white" : ""}`,
                        }}
                    >
                        Calendar
                    </button>
                    <button className="button-styles-remove"
                        onClick={() => setShowing("today")}
                        style={{
                            backgroundColor: `${showing === "today" ? "blue" : ""}`,
                            color: `${showing === "today" ? "white" : ""}`,
                        }}
                    >
                        Today's Bookings
                    </button>
                    <button className="button-styles-remove"
                        onClick={() => setShowing("download")}
                        style={{
                            backgroundColor: `${showing === "download" ? "blue" : ""}`,
                            color: `${showing === "download" ? "white" : ""}`,
                        }}
                    >
                        Download
                    </button>
                </div>
                {showing === "need-approval" ? <NeedApproval /> : ""}
                {showing === "weekly" ? <WeeklyCalendar /> : ""}
                {showing === "today" ? <TodaysBookings /> : ""}
                {showing === "download" ? <DownloadBookings /> : ""}
            </div>
        </>
    );
};

export default AdminBookingsManage;
