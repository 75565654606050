import React from "react";

const NotFoundPage = () => {
    return (
        <>
            <div className="public-wrapper">
                <p>404 - Page Not Found</p>
            </div>
        </>
    );
};

export default NotFoundPage;