import React from "react";
import TraderBookingFlow from "../PageBlocks/BookingForm/TraderBookingFlow";

const BookingPage = () => {
    return (
        <div className="public-wrapper">
            <div
                className="booking-form"
            >
                <TraderBookingFlow />
            </div>
        </div>
    );
};

export default BookingPage;
