import React from 'react';
import PageRenderer from './PageRenderer';

const ListItemRenderer = ({ item }) => {
    const { itemContent, itemImageUrl, itemImageAltText } = item;

    return (
        <>
            <div className='list-item'>
                <div className='list-item-text'>
                    <PageRenderer renderData={itemContent} />
                </div>
                <div className="list-item-image-box">
                    {itemImageUrl ?
                        <img className="list-item-image" src={itemImageUrl} alt={itemImageAltText} /> : ''
                    }
                </div>
            </div>
        </>
    );
};

export default ListItemRenderer;