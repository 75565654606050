import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { PagesContext } from "../../App";

const AdminMenuPage = (props) => {
  return (
    <>
      <div>
        <div className="admin-header-section">
          <h2 className="admin-heading">Bookings</h2>
          <div className="admin-nav-list">
            <div className="admin-nav-item"><Link to="manage-bookings">Manage Bookings</Link></div>
            <div className="admin-nav-item"><Link to="bookings-settings">Bookings Settings</Link></div>
          </div>
        </div>
        <div className="admin-header-section">
          <h2 className="admin-heading">Edit Pages</h2>
          <AdminPagesMenu />
        </div>
        <div className="admin-header-section">
          <h2 className="admin-heading">Manage Pages</h2>
          <div className="admin-nav-list">
            <div className="admin-nav-item">
              <Link to="create-page">Create A Page</Link>
            </div>
            <div className="admin-nav-item">
              <Link to="delete-page">Delete A Page</Link>
            </div>
            <div className="admin-nav-item">
              <Link to="edit-footer">Edit Footer Info</Link>
            </div>
            <div className="admin-nav-item">
              <Link to="edit-header">Edit Header Info</Link>
            </div>
            <div className="admin-nav-item">
              <Link to="edit-gallery-images">Edit Gallery Images</Link>
            </div>
            <div className="admin-nav-item">
              <Link to="order-pages">Order Pages</Link>
            </div>
          </div>
        </div>
        <div className="admin-header-section">
          <h2 className="admin-heading">Manage Admin Users</h2>
          <div className="admin-nav-list">
            <div className="admin-nav-item">
              <Link to="manage-admin-users">Manage Admin Users</Link>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default AdminMenuPage;

const AdminPagesMenu = ({ closeMenu, isNarrowScreen }) => {
  const pages = useContext(PagesContext);

  return (
    <div className="admin-nav-list" onClick={closeMenu}>
      {pages.map((page) => {
        const { id, pageSlug, pageTitle } = page;
        return (
          <div className="admin-nav-item" key={id}>
            <Link to={pageSlug}>{pageTitle}</Link>
          </div>
        );
      })}
    </div>
  );
};
