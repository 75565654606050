import React, { useState, useEffect } from "react";
import { openRequest } from "../../../utils/http";
import PageRenderer from "../PageRenderer";

const AvailableSlots = ({ setStep, setBookingData, allRenderData }) => {
    const { slotSelection } = allRenderData || {};
    const { content } = slotSelection || {};
    const [availableDaysSlots, setAvailableDaysSlots] = useState([]);

    useEffect(() => {
        const getTradingSlotsAvailableNow = async () => {
            try {
                const res = await openRequest("/trading-slots/days-slots-available-now", "GET");
                const { status, data } = res;
                if (status === 200) {
                    const { availableDaysSlots } = data;
                    if (availableDaysSlots) {
                        console.log(availableDaysSlots);
                        setAvailableDaysSlots(availableDaysSlots);
                    }
                } else {
                    alert("An error occured. Please check your internet connection.");
                }
            } catch (error) {
                console.log(error);
            }
        };
        getTradingSlotsAvailableNow();
    }, []);

    const onSelect = (event, slot) => {
        event.preventDefault();
        setBookingData((prev) => {
            return { ...prev, slot };
        });
        setStep((prev) => prev + 1);
    };

    return (
        <>
            <div><PageRenderer renderData={content} /></div>
            <div className="available-slots-list">
                {availableDaysSlots.map((day, index) => {
                    return (
                        <div key={index} style={{ minHeight: "200px" }} className="public-calendar-day">
                            <h2>{day.formattedDay}</h2>
                            <div className="public-calendar-day-slots">
                                {day.slots.map((slot, index) => {
                                    const {
                                        endTime,
                                        locationAddress,
                                        locationName,
                                        startTime,
                                        formattedDay,
                                        placesAvailable
                                    } = slot;

                                    return (<>
                                        <li className="available-slot-card" key={index}>
                                            <div>{locationName}</div>
                                            <div>{locationAddress}</div>
                                            <div>
                                                {formattedDay}, {startTime} - {endTime}
                                            </div>
                                            <div>{placesAvailable} places still available</div>
                                            <div>
                                                <button className="available-slot-button" onClick={(event) => onSelect(event, slot)}>Select</button>
                                            </div>
                                        </li>
                                    </>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default AvailableSlots;