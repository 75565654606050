import React from "react";

const Booking = ({ children, booking }) => {
    const {
        locationName,
        startTime,
        endTime,
        fullName,
        commodity,
        postCode,
        email,
        phoneNumber,
        traderTickboxes,
    } = booking;

    return (
        <>
            <div
                className="booking-wrap"
                style={{
                    whiteSpace: "normal",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    margin: "10px 10px 10px 0",
                    padding: "10px",
                }}
            >
                <div className="booking">
                    <div
                        className="booking-info"
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                                borderBottom: "solid 3px black",
                            }}
                        >
                            <b>{locationName}</b>
                            <div>
                                {startTime} - {endTime}
                            </div>
                        </div>

                        <div
                            className="booking-details"
                        >
                            <b>Full Name:</b>
                            <div>{fullName || "..."}</div>

                            <b>Commodity:</b>
                            <div>{commodity || "..."}</div>

                            <b>Postcode:</b>
                            <div>{postCode || "..."}</div>

                            <b>Email:</b>
                            <div>{email || "..."}</div>

                            <b>Mob:</b>
                            <div>{phoneNumber || "..."}</div>
                        </div>
                        <div style={{ fontSize: "70%" }}>
                            <table>
                                <tbody>
                                    {traderTickboxes.map((tickbox) => {
                                        const { id, tickboxSentence, response } = tickbox;
                                        return (
                                            <tr key={id}>
                                                <td>{tickboxSentence}</td>
                                                <td style={{ paddingLeft: "10px", fontWeight: 'bold' }}>{response}</td>
                                            </tr>
                                        );
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Booking;