import React, { useEffect, useMemo, useState } from "react";

import Fuse from "fuse.js";
import ListItemRenderer from "./ListItemRenderer";
import { openRequest } from "../../utils/http";

const ListPageFetcher = ({ pageId }) => {
  const [items, setItems] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const getPageContent = async () => {
      const { status, data } = await openRequest(`/pages/${pageId}`, "GET");
      if (status === 200) {
        setItems(data.listPageItems);
        setPageTitle(data.pageTitle);
      } else {
        // API ERROR
      }
    };
    getPageContent();
  }, [pageId]);

  const fuse = useMemo(() => {
    const options = {
      keys: ["itemContent.children.children.text"],
      threshold: 0.3,
      includeScore: true,
    };
    return new Fuse(items, options);
  }, [items]);

  const filteredItems = useMemo(() => {
    if (!searchTerm) return items;
    return fuse.search(searchTerm).map((result) => result.item);
  }, [fuse, searchTerm, items]);

  console.log(filteredItems);

  return (
    <>
      <h1 style={{ fontFamily: "PoppinsExtra", marginBottom: "20px" }}>
        {pageTitle}
      </h1>
      {items.length >= 3 && (
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "100%", padding: "10px", marginBottom: "40px" }}
        />
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: "3rem",
        }}
      >
        {filteredItems.map((item) => (
          <a
            href={item.itemContent[0].children[0].url}
            key={item.id}
            style={{ display: "block", textDecoration: "none" }}
          >
            <img
              src={item.itemImageUrl}
              alt={item.itemImageAltText}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                aspectRatio: "1/1",
              }}
            />
            <h2
              style={{
                fontFamily: "PoppinsExtra",
                fontSize: "1.2rem",
                margin: "10px 0",
                color: "black",
              }}
            >
              {item.itemContent[0].children[0].children[0].text}
            </h2>
          </a>
        ))}
      </div>
    </>
  );
};

export default ListPageFetcher;
