import React, { useEffect, useState } from "react";
import { authRequest, openRequest } from "../../utils/http";
import RichTextEditor from "./AdminUtils/RichTextEditor";
import slugify from "slugify";

const AdminEditPage = ({ page }) => {
    const { id: pageId, pageSlug, pageType, pageTitle, pageContent } = page;
    const [loading, setLoading] = useState(true);
    const [editorValue, setEditorValue] = useState(pageContent);
    const [updatedPageSlug, setUpdatedPageSlug] = useState(pageSlug);
    const [updatedPageTitle, setUpdatedPageTitle] = useState(pageTitle);
    const [changesSaved, setChangesSaved] = useState(true);

    useEffect(() => {
        const getPageContent = async () => {
            const { status, data } = await openRequest(`/pages/${pageId}`, "GET");
            if (status === 200) {
                setEditorValue(data.pageContent);
                setLoading(false);
            } else {
                // API ERROR
            }
        };
        getPageContent();
    }, [pageId]);

    const onSave = async () => {
        const savePageContent = async () => {
            const res = await authRequest("/pages", "PUT", {
                page: {
                    id: pageId,
                    pageTitle: updatedPageTitle,
                    pageType: pageType,
                    pageSlug: updatedPageSlug,
                    pageContent: editorValue,
                }
            });
            if (res.status === 200) {
                window.location.href = `/admin/${updatedPageSlug}`;
            } else {
                // ERROR
            }
        };
        savePageContent();
    };

    const onEditorUpdate = (value) => {
        setChangesSaved(false);
        setEditorValue(value);
    };

    const handleTitleChange = (event) => {
        setChangesSaved(false);
        setUpdatedPageTitle(event.target.value);
        setUpdatedPageSlug(slugify(event.target.value, { strict: true, lower: true }));
    }

    return loading ? (
        "Loading Editor..."
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div style={{ width: "80%", marginTop: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {changesSaved ? (
                            <div style={{ color: "green" }}>All changes saved</div>
                        ) : (
                            <div style={{ color: "orange" }}>Unsaved changes</div>
                        )}
                    </div>
                    <button className={``} onClick={onSave}>
                        Save
                    </button>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <h3>Page Title In Nav Bar</h3>
                    <input
                        disabled={pageTitle === "Home" ? true : false}
                        type="text"
                        name="pageTitle"
                        value={updatedPageTitle}
                        onChange={handleTitleChange}
                    />
                </div>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                    <span><b>Page Slug:</b>{" /"}</span><span>{pageSlug === 'home' ? "" : updatedPageSlug}</span>
                </div>
                <RichTextEditor
                    editorValue={editorValue}
                    setUpdatedValue={onEditorUpdate}
                />
            </div>
        </div>
    );
};

export default AdminEditPage;