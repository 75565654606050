import React, { useState } from "react";
import { authRequest } from "../../utils/http";

const AdminOrderPages = ({ pages }) => {
    return (
        <>
            <p>The Booking page is always shown in 5th place in the header.</p>
            <p style={{ marginBottom: "40px" }} >Hidden pages are not shown in the header, and so don't have an order.</p>
            {pages.length > 0 ? pages.filter((page) => page.pageType === "standard" || page.pageType === "list").map((page) => <OrderPage key={page.id} page={page} />) : ""}
        </>
    );
};

export default AdminOrderPages;

const OrderPage = ({ page }) => {
    const { id: pageId, pageTitle, headerOrdering } = page;
    const [updatedHeaderOrdering, setUpdatedHeaderOrdering] = useState(headerOrdering);

    const onSave = async () => {
        const savePageContent = async () => {
            const res = await authRequest("/pages/header-ordering", "PUT", {
                id: pageId,
                headerOrdering: updatedHeaderOrdering,
            });
            if (res.status === 200) {
                window.location.reload();
            } else {
                // ERROR
            }
        };
        savePageContent();
    };


    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "20px",
                padding: "20px",
                border: "3px solid black",
                borderRadius: "5px",
            }}
        >
            <p>{pageTitle}</p>
            <input type="number" value={updatedHeaderOrdering} onChange={(e) => { console.log(e.target.value); return setUpdatedHeaderOrdering(e.target.value); }} />
            <button onClick={onSave}>Save</button>
        </div>
    );
};