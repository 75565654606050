import React, { useState } from "react";
import { authRequest, openRequest } from "../../utils/http";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordResetMode, setPasswordResetMode] = useState(false);
  const [firstTouch, setFirstTouch] = useState(true);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const login = (body, callback) => {
    openRequest("/admin-users/login", "POST", body)
      .then((res) => {
        if (res && res.status === 200) {
          const { token, email } = res.data;
          localStorage.setItem("token", token);
          setEmail(email);
          callback(true, null);
        } else {
          const { message } = res.data;
          localStorage.clear();
          setEmail("");
          callback(false, message);
        }
      })
      .catch(() => {
        console.log("An error occurred.");
        setMessage("Something went wrong. Are you online?");
      });
  };

  const handleSubmit = async (event) => {
    if (!passwordResetMode) {
      const body = {
        email,
        password,
      };

      login(body, (success, message) => {
        if (success) {
          window.location.assign("/admin");
        } else {
          setMessage(message);
        }
      });
    } else {
      try {
        const res = await authRequest(
          "/admin-users/send-password-reset-email",
          "POST",
          {
            email,
          }
        );
        const { status } = res;
        if (status === 200) {
          setPasswordResetMode(false);
          setFirstTouch(true);
          setResetEmailSent(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const toggleResetMode = () => {
    setPassword("");
    setFirstTouch(false);
    setMessage("");
    setPasswordResetMode((passwordResetMode) => !passwordResetMode);
  };

  return (
    <div className="main-outer">
      <div className="main centered-content">
        <div className="login-form">
          {resetEmailSent ? (
            <div>
              If an account with that email exists, then a password reset email
              has been sent.
            </div>
          ) : (
            <fieldset className="signup-fieldset">
              <legend
                style={{ color: `${passwordResetMode ? "orange" : "black"}` }}
              >
                {passwordResetMode ? (
                  `Send password reset email to ${email || "your email"}?`
                ) : (
                  <h2>Login</h2>
                )}
              </legend>
              <form
                className="login-form"
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit(event);
                }}
              >
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Email"
                />
                {passwordResetMode ? (
                  ""
                ) : (
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Password"
                  />
                )}
                <input
                  type="submit"
                  value={`${passwordResetMode ? "Send" : "Login"}`}
                />
              </form>
              <p className="user-error">{message}</p>
              <button style={{ marginTop: "20px" }} onClick={toggleResetMode}>
                {!firstTouch && passwordResetMode ? "Cancel" : "Reset Password"}
              </button>
            </fieldset>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
