import React, { useEffect, useState } from "react";
import { authRequest } from "../../../utils/http";
import Booking from "./Booking";
import BookingControl from "./BookingControl";
import convertStatusToUserStatus from "./convertStatusToUserStatus";

const NeedApproval = () => {
  const [bookings, setBookings] = useState([]);
  const [needApprovalCount, setNeedApprovalCount] = useState('');

  useEffect(() => {
    const needApprovalCount = bookings.filter(booking => booking.status === "verified").length;
    setNeedApprovalCount(needApprovalCount);
  }, [bookings]);

  useEffect(() => {
    const getPageContent = async () => {
      const { status, data } = await authRequest("/bookings/need-approval", "GET");
      if (status === 200) {
        const { bookings } = data;
        if (bookings) {
          const verifiedBookings = bookings.filter(
            (booking) => booking.status === "verified"
          );
          setBookings(verifiedBookings);
        }
      } else {
        console.error("An error has occurred");
      }
    };
    getPageContent();
  }, []);

  const uniqueDays = [];

  return (
    <div className="calender_container">
      <h2 className="admin-message" style={{
        marginTop: "40px", textDecoration: `${needApprovalCount > 0 ? "underline 6px solid orange" : "none"}`
      }}>{needApprovalCount} Bookings Need Approval...</h2>
      <ul className="bookings-list">
        {!!bookings.length &&
          bookings.map((booking) => {
            const adminUserStatus = convertStatusToUserStatus(booking.status);
            if (!uniqueDays.includes(booking.formattedDay)) {
              uniqueDays.push(booking.formattedDay);
              return (
                <div key={booking.id}>
                  <div>
                    <h2>{booking.formattedDay}</h2>
                  </div>
                  <Booking
                    booking={booking}
                    convertStatusToUserStatus={convertStatusToUserStatus}
                  >
                    <BookingControl adminUserStatus={adminUserStatus} id={booking.id} convertStatusToUserStatus={convertStatusToUserStatus} booking={booking} setBookings={setBookings} />
                  </Booking>
                </div>
              );
            } else {
              return (
                <Booking
                  key={booking.id}
                  booking={booking}
                  convertStatusToUserStatus={convertStatusToUserStatus}
                >
                  <BookingControl adminUserStatus={adminUserStatus} id={booking.id} convertStatusToUserStatus={convertStatusToUserStatus} booking={booking} setBookings={setBookings} />
                </Booking>
              );
            }
          })}
      </ul>
    </div>
  );
};

export default NeedApproval;
