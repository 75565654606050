const convertStatusToUserStatus = (status) => {
    switch (status) {
        case "verified":
            return { color: "orange", text: "Pending" };
        case "approved":
            return { color: "lightgreen", text: "Approved" };
        case "denied":
            return { color: "#FFCCCB", text: "Denied" };
        default:
            return "";
    }
};

export default convertStatusToUserStatus;