import React, { useEffect, useState } from "react";
import { authRequest, openRequest } from "../../utils/http";
import RichTextEditor from "./AdminUtils/RichTextEditor";

const AdminEditBookingsPage = () => {
  const [renderablesInitial, setRenderablesInitial] = useState([]);

  console.log(renderablesInitial);

  useEffect(() => {
    const getPageContent = async () => {
      const { status, data } = await openRequest('/bookings-page-content', "GET");
      if (status === 200) {
        const formContents = Object.values(data);
        const formContentsSorted = formContents.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        setRenderablesInitial(formContentsSorted);
      } else {
        // API ERROR
      }
    };
    getPageContent();
  }, []);

  return renderablesInitial.map(renderable => <RichTextUpdater renderData={renderable} />);
};

export default AdminEditBookingsPage;

const RichTextUpdater = ({ renderData }) => {
  const { content, stepName } = renderData;
  const [initialValue, setInitialValue] = useState(content);
  const [updatedValue, setUpdatedValue] = useState(null);
  const [changesSaved, setChangesSaved] = useState(true);

  const onSave = async (stepName) => {
    const savePageContent = async (stepName) => {
      const res = await authRequest('/bookings-page-content', "POST", {
        content: updatedValue,
        stepName: stepName
      });
      if (res.status === 200) {
        console.log(res.data);
        setInitialValue(res.data.content);
        setChangesSaved(true);
      } else {
        // ERROR
      }
    };
    savePageContent(stepName);
  };

  const onUpdate = (value) => {
    setUpdatedValue(value);
    setChangesSaved(false);
  };

  return content ? (
    <div style={{
      border: "solid 3px black",
      marginBottom: "20px"
    }}>
      <h3 style={{ margin: "10px 0px 10px 5px", fontWeight: "800" }}>{stepName}</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <div style={{ width: "80%", marginTop: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {changesSaved ? (
                <div style={{ color: "green" }}>All changes saved</div>
              ) : (
                <div style={{ color: "orange" }}>Unsaved changes</div>
              )}
            </div>
            <button className={``} onClick={() => onSave(stepName)}>
              Save
            </button>
          </div>

          <RichTextEditor
            editorValue={initialValue}
            setUpdatedValue={onUpdate}
          />
        </div>
      </div>
    </div >
  ) : (
    "Loading Editor..."
  );
}

