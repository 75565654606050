import React, { useState, useContext } from "react";
import { authRequest } from "../../utils/http";
import { HeaderContext } from "../../App";

const AdminEditHeader = () => {
    const headerLinks = useContext(HeaderContext);
    const { email, logoUrl, facebookUrl, instagramUrl, twitterUrl, snapchatUrl, youtubeUrl, linkedinUrl, tiktokUrl, whatsappUrl } = headerLinks;
    const [updatedEmail, setUpdatedEmail] = useState(email);
    const [updatedLogoUrl, setUpdatedLogoUrl] = useState(logoUrl);
    const [updatedFacebookUrl, setUpdatedFacebookUrl] = useState(facebookUrl);
    const [updatedInstagramUrl, setUpdatedInstagramUrl] = useState(instagramUrl);
    const [updatedTwitterUrl, setUpdatedTwitterUrl] = useState(twitterUrl);
    const [updatedSnapchatUrl, setUpdatedSnapchatUrl] = useState(snapchatUrl);
    const [updatedYoutubeUrl, setUpdatedYoutubeUrl] = useState(youtubeUrl);
    const [updatedLinkedinUrl, setUpdatedLinkedinUrl] = useState(linkedinUrl);
    const [updatedTiktokUrl, setUpdatedTiktokUrl] = useState(tiktokUrl);
    const [updatedWhatsappUrl, setUpdatedWhatsappUrl] = useState(whatsappUrl);

    const onSave = async () => {
        const savePageContent = async () => {
            const res = await authRequest("/header", "PUT", {
                email: updatedEmail,
                logoUrl: updatedLogoUrl,
                facebookUrl: updatedFacebookUrl,
                instagramUrl: updatedInstagramUrl,
                twitterUrl: updatedTwitterUrl,
                snapchatUrl: updatedSnapchatUrl,
                youtubeUrl: updatedYoutubeUrl,
                linkedinUrl: updatedLinkedinUrl,
                tiktokUrl: updatedTiktokUrl,
                whatsappUrl: updatedWhatsappUrl,
            });
            if (res.status === 200) {
                alert("Changes saved!");
            } else {
                // ERROR
            }
        };
        savePageContent();
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div style={{ width: "80%", marginTop: "20px" }}>
                <h1 style={{ marginBottom: "20px" }}>Edit Header Links</h1>
                <p>Links with no URL saved will not be shown.</p>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <label className="header-links-admin-label">Email</label>
                    <input type="text" name="email" value={updatedEmail} onChange={(event) => setUpdatedEmail(event.target.value)} />
                    <label className="header-links-admin-label">Logo URL</label>
                    <input type="text" name="logoUrl" value={updatedLogoUrl} onChange={(event) => setUpdatedLogoUrl(event.target.value)} />
                    <label className="header-links-admin-label">Facebook URL</label>
                    <input type="text" name="facebookUrl" value={updatedFacebookUrl} onChange={(event) => setUpdatedFacebookUrl(event.target.value)} />
                    <label className="header-links-admin-label">Instagram URL</label>
                    <input type="text" name="instagramUrl" value={updatedInstagramUrl} onChange={(event) => setUpdatedInstagramUrl(event.target.value)} />
                    <label className="header-links-admin-label">Twitter URL</label>
                    <input type="text" name="twitterUrl" value={updatedTwitterUrl} onChange={(event) => setUpdatedTwitterUrl(event.target.value)} />
                    <label className="header-links-admin-label">Snapchat URL</label>
                    <input type="text" name="snapchatUrl" value={updatedSnapchatUrl} onChange={(event) => setUpdatedSnapchatUrl(event.target.value)} />
                    <label className="header-links-admin-label">Youtube URL</label>
                    <input type="text" name="youtubeUrl" value={updatedYoutubeUrl} onChange={(event) => setUpdatedYoutubeUrl(event.target.value)} />
                    <label className="header-links-admin-label">Linkedin URL</label>
                    <input type="text" name="linkedinUrl" value={updatedLinkedinUrl} onChange={(event) => setUpdatedLinkedinUrl(event.target.value)} />
                    <label className="header-links-admin-label">Tiktok URL</label>
                    <input type="text" name="tiktokUrl" value={updatedTiktokUrl} onChange={(event) => setUpdatedTiktokUrl(event.target.value)} />
                    <label className="header-links-admin-label">Whatsapp URL</label>
                    <input type="text" name="whatsappUrl" value={updatedWhatsappUrl} onChange={(event) => setUpdatedWhatsappUrl(event.target.value)} />
                </div>
                <button style={{ margin: "40px 0 40px 0" }} className={``} onClick={onSave}>
                    Save
                </button>
            </div>
        </div>
    );
};

export default AdminEditHeader;