import React, { useState, useContext } from "react";
import { authRequest } from "../../utils/http";
import { PagesContext } from "../../App";

const AdminDeletePage = () => {
    const pages = useContext(PagesContext);

    return (<>
        <h2>Delete A Page</h2>
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >

            <div style={{ width: "80%", marginTop: "20px" }}>
                {pages.map((page) => {
                    return page.pageSlug !== "book" ? <DeletePageControl key={page.id} page={page} /> : "";
                })}
            </div >

        </div >
    </>);
};

export default AdminDeletePage;

const DeletePageControl = ({ page }) => {
    const [deleteProtected, setDeleteProtected] = useState(true);

    const onDelete = async (page) => {
        const res = await authRequest('/pages', "DELETE", {
            page
        });
        if (res.status === 200) {
            window.location.href = "/admin/delete-page";
        } else {
            // ERROR
        }
    };

    const onChange = (event) => {
        if (event.target.value === page.pageSlug) {
            setDeleteProtected(false);
        } else {
            setDeleteProtected(true);
        }
    }

    return (<>
        <div key={page.id} style={{ border: "solid 2px black", borderRadius: "5px", marginBottom: "10px", "padding": "10px" }}>
            <h2>{page.pageTitle}</h2>
            <div style={{ display: "flex" }}>Page Slug:<p>{" /" + (page.pageSlug === 'home' ? "" : page.pageSlug)}</p></div>
            <div style={{ display: "flex" }}>
                {page.pageSlug === 'home' ? <div>You cannot delete the home page.</div> : <><div><p>Please first type the page slug of the you would like to delete here.</p><input onChange={onChange}></input></div><button disabled={deleteProtected} onClick={() => onDelete(page)}>Confirm Delete Page</button></>}
            </div>
        </div>
    </>);
}