import AdminEditBookingsPage from "./AdminEditBookingsPage";
import AdminEditGridPage from "./AdminEditGridPage";
import AdminEditListPage from "./AdminEditListPage";
import AdminEditStandardPage from "./AdminEditStandardPage";
import React from "react";

const AdminEditPageChoice = ({ page }) => {
  const { pageType } = page;
  const renderSwitch = (pageType) => {
    switch (pageType) {
      case "standard":
        return <AdminEditStandardPage page={page} />;
      case "standard-hidden":
        return <AdminEditStandardPage page={page} />;
      case "booking":
        return <AdminEditBookingsPage page={page} />;
      case "list":
        return <AdminEditListPage page={page} />;
      case "grid":
        return <AdminEditGridPage page={page} />;
      default:
        return "";
    }
  };

  return <>{renderSwitch(pageType)}</>;
};

export default AdminEditPageChoice;
