import React, { useEffect, useState } from "react";
import { openRequest } from "../../utils/http";
import ListItemRenderer from "./ListItemRenderer";

const ListPageFetcher = ({ pageId }) => {
    const [items, setItems] = useState([]);
    const [pageTitle, setPageTitle] = useState("");

    useEffect(() => {
        const getPageContent = async () => {
            const { status, data } = await openRequest(`/pages/${pageId}`, "GET");
            if (status === 200) {
                setItems(data.listPageItems);
                setPageTitle(data.pageTitle);
            } else {
                // API ERROR
            }
        };
        getPageContent();
    }, [pageId]);

    return (
        <>
            <h1 style={{ fontFamily: "PoppinsExtra", marginBottom: "40px" }}>{pageTitle}</h1>
            {items.map((item, index) => {
                return (
                    <ListItemRenderer key={index} item={item} />
                );
            })}
        </>
    );
};

export default ListPageFetcher;