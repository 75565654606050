import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { authRequest } from "../utils/http";
import Loading from "../elements/Loading";

const ResetPassword = () => {
    const [loadingResult, setLoadingResult] = useState(false);
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("");

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        setLoadingResult(true);
        try {
            const res = await authRequest(`/admin-users/reset-password`, "POST", {
                token,
                password,
            });
            const { status, data } = res;
            if (status === 200) {
                setMessage("Your password has been reset. You can now use your new password to login.");
            } else {
                console.log(data);
                setMessage(data.message || "Something went wrong - please try again.");
            }
            setLoadingResult(false);
        } catch (e) {
            console.log("Error fetching data");
        }
    };

    return (
        <div className="public-wrapper">
            <div className="">
                <h2>Reset Password</h2>
                {
                    loadingResult ? <Loading /> : (
                        message ?
                            <p>{message}</p>
                            :
                            <form className="form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="password">New Password</label>
                                    <input
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={(event) => setPassword(event.target.value)}
                                        placeholder="Password"
                                    />
                                    <button onClick={handleSubmit}>Reset Password</button>
                                </div>
                            </form>
                    )
                }
            </div>
        </div>
    );
};

export default ResetPassword;
