import React, { useState } from "react";
import PageRenderer from "../PageRenderer";

const FoodLoop = ({ setStep, setBookingData, allRenderData }) => {
    const [foodLoopStep, setFoodLoopStep] = useState('question');

    const { foodQuestion, foodConditions, foodInstructions } = allRenderData || {};
    const { content: foodQuestionContent } = foodQuestion || {};
    const { content: foodConditionsContent } = foodConditions || {};
    const { content: foodInstructionsContent } = foodInstructions || {};

    return (
        <div>
            {foodLoopStep === 'question' ? <>
                <div><PageRenderer renderData={foodQuestionContent} /></div>
                <div>
                    <button onClick={() => setFoodLoopStep('conditions')}>
                        Food Items
                    </button>
                    <button onClick={() => setStep((prev) => prev + 1)}>
                        Non-food Items
                    </button>
                </div>
            </> : ""}

            {foodLoopStep === 'conditions' ? <>
                <div><PageRenderer renderData={foodConditionsContent} /></div>
                <div>
                    <button onClick={() => setStep((prev) => prev + 1)}>
                        Yes
                    </button>
                    <button onClick={() => setFoodLoopStep('instructions')}>
                        Not yet
                    </button>
                </div>

            </> : ""}

            {foodLoopStep === 'instructions' ? <>
                <div><PageRenderer renderData={foodInstructionsContent} /></div>
            </> : ""}
        </div>
    );
};

export default FoodLoop;