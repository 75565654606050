import React from 'react';

const MustConfirm = ({ bookingData }) => {
    return (
        <>
            <div>An email has been sent to {bookingData.traderDetails.email}</div>
            <h2 style={{ marginTop: "20px" }}>You must click the button in the email to complete this application!</h2>
            <h2>Check your email now.</h2>
        </>
    );
};

export default MustConfirm;