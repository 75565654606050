import React, { useCallback, useMemo } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { Element, Leaf } from "../AdminPages/AdminUtils/RichTextRenderers";

const PageRenderer = ({ renderData }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  return (
    <>
      {renderData ? (
        <Slate editor={editor} value={renderData}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly
            placeholder=""
          />
        </Slate>
      ) : (
        ""
      )}
    </>
  );
};

export default PageRenderer;
