import React, { useEffect, useState } from "react";

import FacebookLogo from "../../assets/facebook_white.svg";
import InstagramLogo from "../../assets/instagram_white.svg";
import LinkedinLogo from "../../assets/linkedin_white.svg";
import SnapchatLogo from "../../assets/snapchat_white.svg";
import TiktokLogo from "../../assets/tiktok_white.svg";
import TwitterLogo from "../../assets/twitter_white.svg";
import WhatsappLogo from "../../assets/whatsapp_white.svg";
import YoutubeLogo from "../../assets/youtube_white.svg";

const SocialLinks = ({ headerLinks, isFooter }) => {
  const { email } = headerLinks || {};
  const [socialLinksWithIcons, setSocialLinksWithIcons] = useState([]);

  useEffect(() => {
    if (!headerLinks) return;
    const socialLinksWithIcons = Object.entries(headerLinks).map(
      (socialLink) => {
        if (socialLink) {
          const [key, value] = socialLink;
          switch (key) {
            case "facebookUrl":
              return {
                icon: FacebookLogo,
                url: value,
              };
            case "instagramUrl":
              return {
                icon: InstagramLogo,
                url: value,
              };
            case "twitterUrl":
              return {
                icon: TwitterLogo,
                url: value,
              };
            case "snapchatUrl":
              return {
                icon: SnapchatLogo,
                url: value,
              };
            case "youtubeUrl":
              return {
                icon: YoutubeLogo,
                url: value,
              };
            case "linkedinUrl":
              return {
                icon: LinkedinLogo,
                url: value,
              };
            case "tiktokUrl":
              return {
                icon: TiktokLogo,
                url: value,
              };
            case "whatsappUrl":
              return {
                icon: WhatsappLogo,
                url: value,
              };
            default:
              return null;
          }
        }
      }
    );
    setSocialLinksWithIcons(socialLinksWithIcons);
  }, [headerLinks]);

  return (
    <div className="social-links">
      {!isFooter && (
        <a className="header-email social-link" href={`mailto:${email}`}>
          {email}
        </a>
      )}
      <div className="socials-icons">
        {socialLinksWithIcons.map((link, index) => {
          if (!link) return null;
          const { icon, url } = link;
          if (!url) return null;
          return (
            <a key={index} className="social-link" href={url}>
              <img
                className=""
                style={{ height: "28px" }}
                src={icon}
                alt="Our Little Markets Social Media Link"
              ></img>
            </a>
          );
        })}
      </div>
    </div>
  );
};

// https://www.instagram.com/ourlittlemarketscic
// https://twitter.com/ourlittlemkts

export default SocialLinks;
