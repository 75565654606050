import React, { useState, useRef, useEffect } from "react";
import PageRenderer from "../PageRenderer";
import RECAPTCHA from "react-google-recaptcha";
import { openRequest } from "../../../utils/http";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const COMMODITY_OPTIONS = [
    "Household goods/toiletries",
    "Textiles and clothes",
    "Books",
    "Toys",
    "Fruit and vegetables",
    "Food (hot/cold)",
    "Fashion (clothes and accessorise)",
    "Second-hand items",
    "Charity or community stall",
    "Other, please state",
];

// To add or change the tickboxes, just change the array below
const TRADER_TICKBOXES = [
    "I have my own public liability insurance",
    "I am employed by Camden Council",
    "I am a licenced trader in Camden",
    "I have a food hygiene certificate",
    "I am certified for hot works",
    "I am registered with Companies House",
];

const TraderDetails = ({ setStep, bookingData, setBookingData, allRenderData }) => {
    const captchaRef = useRef(null)
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [commodityListSelection, setCommodityListSelection] = useState("");
    const [showCommodityTextInput, setShowCommodityTextInput] = useState(false);
    const [traderTickboxes, setTraderTickboxes] = useState(TRADER_TICKBOXES.map((tickboxSentence, index) => {
        return {
            id: index, tickboxSentence: tickboxSentence, response: ""
        }
    }));
    const { provideDetails } = allRenderData;
    const { content } = provideDetails;

    const { traderDetails } = bookingData;
    const { email, fullName, phoneNumber, postCode, commodity } = traderDetails;

    useEffect(() => {
        if (commodityListSelection === "Other, please state") {
            onChange({ target: { name: "commodity", value: "" } });
            setShowCommodityTextInput(true);
        } else {
            setShowCommodityTextInput(false);
        }
    }, [commodityListSelection]);

    const onChange = async (event) => {
        setBookingData((prev) => {
            return {
                ...prev,
                traderDetails: {
                    ...prev.traderDetails,
                    [event.target.name]: event.target.value,
                },
            };
        });
    };

    const onSelect = async (event) => {
        setBookingData((prev) => {
            return {
                ...prev,
                traderDetails: {
                    ...prev.traderDetails,
                    commodity: event.value,
                },
            };
        });
        setCommodityListSelection(event.value);
    };

    const onRadioChange = async (event) => {
        setTraderTickboxes(prev => prev.map(element => {
            if (element.id === parseInt(event.target.name)) {
                return {
                    ...element,
                    response: event.target.value
                }
            } else {
                return element
            }
        }));
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setMessage('');
        const captchaToken = captchaRef.current.getValue();
        console.log('captchaToken', captchaToken);
        captchaRef.current.reset();
        if (captchaToken) {
            const res = await openRequest("/bookings", "POST", {
                bookingData,
                traderTickboxes,
                reCaptchaToken: captchaToken
            });
            if (res.status === 200) {
                setStep("final");
            } else {
                setLoading(false);
                const { message } = res.data;
                setMessage(message);
                // ERROR SCREEN
            }
        } else {
            setMessage('Please check the "I\'m Not A Robot" box');
        }
    };

    return (
        <> {loading ? 'Please wait...' :
            <>
                <div><PageRenderer renderData={content} /></div>
                <form style={{
                    display: "flex",
                    flexDirection: "column"
                }} onSubmit={onSubmit}>
                    <input
                        className="final-step-input"
                        required
                        type="text"
                        name="fullName"
                        value={fullName}
                        onChange={(event) => onChange(event)}
                        placeholder="Full Name"
                    />
                    <input
                        className="final-step-input"
                        required
                        type="text"
                        name="email"
                        value={email}
                        onChange={(event) => onChange(event)}
                        placeholder="Email"
                    />
                    <input
                        className="final-step-input"
                        required
                        type="text"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(event) => onChange(event)}
                        placeholder="Phone Number"
                    />
                    <input
                        className="final-step-input"
                        required
                        type="text"
                        name="postCode"
                        value={postCode}
                        onChange={(event) => onChange(event)}
                        placeholder="Postcode"
                    />
                    <Dropdown className="final-step-input" style={{ marginBottom: "10px" }} options={COMMODITY_OPTIONS} onChange={onSelect} value={""} placeholder="Commodity - what will you sell?" />
                    {showCommodityTextInput ? <input
                        style={{ marginBottom: "10px" }}
                        className="final-step-input"
                        required
                        type="text"
                        name="commodity"
                        value={commodity}
                        onChange={(event) => onChange(event)}
                        placeholder="Commodity - please state..."
                    /> : ""}
                    <p style={{ marginTop: "20px" }}>Please answer the following questions</p>
                    {traderTickboxes.map((tickbox, index) => {
                        const { tickboxSentence, response } = tickbox;
                        return (
                            <div className="trader-question-card" key={index}>
                                <p style={{ marginRight: "10px" }}>{tickboxSentence}</p>
                                <div style={{ display: "flex" }}>
                                    <input required type="radio" onChange={onRadioChange} name={`${index}`} checked={response === "Yes"} value="Yes" /> Yes
                                    <input required type="radio" onChange={onRadioChange} name={`${index}`} checked={response === "No"} value="No" /> No
                                </div>
                            </div>
                        )
                    })}
                    <RECAPTCHA sitekey={'6Lczwc4kAAAAAAIFsg0rOSuvWDqNNcgthSLAPPts'} ref={captchaRef} />
                    <input style={{ marginRight: "0px", maxWidth: "400px" }} type="submit" value="Submit" />
                    <p style={{ marginTop: '1em', color: 'orange', fontWeight: 600 }}>{message}</p>
                </form>
            </>
        }</>
    );
};

export default TraderDetails;