import React, { useEffect, useState } from "react";
import { authRequest, openRequest } from "../../utils/http";

import RichTextEditor from "./AdminUtils/RichTextEditor";
import slugify from "slugify";

const AdminEditGridPage = ({ page }) => {
  const { id: pageId, pageType, pageSlug, pageTitle } = page;
  const [loading, setLoading] = useState(true);
  const [updatedPageSlug, setUpdatedPageSlug] = useState(pageSlug);
  const [updatedPageTitle, setUpdatedPageTitle] = useState(pageTitle);
  const [changesSaved, setChangesSaved] = useState(true);
  const [listPageItems, setListPageItems] = useState([]);
  const [newItemShowing, setNewItemShowing] = useState(false);

  useEffect(() => {
    const getPage = async () => {
      const { status, data } = await openRequest(`/pages/${pageId}`, "GET");
      if (status === 200) {
        const { listPageItems } = data;
        setListPageItems(listPageItems);
        setLoading(false);
      } else {
        // API ERROR
      }
    };
    getPage();
  }, [pageId]);

  const onSave = async () => {
    const savePageContent = async () => {
      const res = await authRequest("/pages", "PUT", {
        page: {
          id: pageId,
          pageType: pageType,
          pageTitle: updatedPageTitle,
          pageSlug: updatedPageSlug,
        },
      });
      if (res.status === 200) {
        window.location.href = `/admin/${updatedPageSlug}`;
      } else {
        // ERROR
      }
    };
    savePageContent();
  };

  const handleTitleChange = (event) => {
    setChangesSaved(false);
    setUpdatedPageTitle(event.target.value);
    setUpdatedPageSlug(
      slugify(event.target.value, { strict: true, lower: true })
    );
  };

  const clearNewItemAndSetListPageItems = (newListPageItems) => {
    setNewItemShowing(false);
    setListPageItems(newListPageItems);
  };

  return loading ? (
    "Loading Editor..."
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "80%", marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              border: "solid 2px black",
              width: "100%",
              padding: "20px",
              marginBottom: "40px",
              borderRadius: "5px",
            }}
          >
            <div>
              {changesSaved ? (
                <div style={{ color: "green" }}>All changes saved</div>
              ) : (
                <div style={{ color: "orange" }}>Unsaved changes</div>
              )}
            </div>
            <div style={{ marginBottom: "20px" }}>
              <h3>Page Title In Nav Bar</h3>
              <input
                disabled={pageTitle === "Home" ? true : false}
                type="text"
                name="pageTitle"
                value={updatedPageTitle}
                onChange={handleTitleChange}
              />
            </div>
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <span>
                <b>Page Slug:</b>
                {" /"}
              </span>
              <span>{pageSlug === "home" ? "" : updatedPageSlug}</span>
            </div>
            <button onClick={onSave}>Save</button>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "1rem",
          }}
        >
          {listPageItems.map((item) => {
            return (
              <ListItemEditor
                key={item.id}
                pageId={pageId}
                item={item}
                setListPageItems={setListPageItems}
              />
            );
          })}
          {newItemShowing ? (
            <ListItemEditor
              pageId={pageId}
              isNewItem={true}
              item={{}}
              setListPageItems={clearNewItemAndSetListPageItems}
            />
          ) : (
            <button onClick={() => setNewItemShowing(true)}>
              Add New Item
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEditGridPage;

const ListItemEditor = ({ item, isNewItem, setListPageItems, pageId }) => {
  const { itemImageUrl, itemImageAltText } = item;
  const [deleteMode, setDeleteMode] = useState(false);
  const [changesSaved, setChangesSaved] = useState(true);
  const [updatedItemImageUrl, setUpdatedItemImageUrl] = useState(
    itemImageUrl || ""
  );
  const [updatedItemImageAltText, setUpdatedItemImageAltText] = useState(
    itemImageAltText || ""
  );
  const [updatedItemTitle, setUpdatedItemTitle] = useState(
    item.itemContent?.[0]?.children?.[0]?.children?.[0]?.text || ""
  );
  const [updatedItemLink, setUpdatedItemLink] = useState(
    item.itemContent?.[0]?.children?.[0]?.url || ""
  );

  const onSave = async () => {
    const savePageContent = async () => {
      const res = await authRequest(
        "/list-page-items",
        isNewItem ? "POST" : "PUT",
        {
          listPageItem: {
            id: isNewItem ? null : item.id,
            pageId: pageId,
            itemContent: [
              {
                type: "paragraph",
                children: [
                  {
                    children: [{ text: updatedItemTitle }],
                    url: updatedItemLink,
                    type: "link",
                  },
                ],
              },
            ],
            itemOrdering: 0,
            itemImageUrl: updatedItemImageUrl,
            itemImageAltText: updatedItemImageAltText,
          },
        }
      );
      const { status, data } = res;
      if (status === 200 && data) {
        setChangesSaved(true);
        setListPageItems(data);
      } else {
        // ERROR
      }
    };
    savePageContent();
  };

  const onDelete = async () => {
    const deletePageContent = async () => {
      const res = await authRequest("/list-page-items", "DELETE", {
        id: item.id,
      });
      const { status, data } = res;
      if (status === 200 && data) {
        window.location.reload();
      } else {
        // ERROR
      }
    };
    deletePageContent();
  };

  const onAltTextChange = (event) => {
    setChangesSaved(false);
    setUpdatedItemImageAltText(event.target.value);
  };

  const onImageUrlChange = (event) => {
    setChangesSaved(false);
    setUpdatedItemImageUrl(event.target.value);
  };

  const onTitleChange = (event) => {
    setChangesSaved(false);
    setUpdatedItemTitle(event.target.value);
  };

  const onLinkChange = (event) => {
    setChangesSaved(false);
    setUpdatedItemLink(event.target.value);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: `${deleteMode ? "lightcoral" : ""}`,
          border: "solid 2px black",
          padding: "20px",
          borderRadius: "5px",
        }}
      >
        {deleteMode ? <button onClick={onDelete}>Confirm Delete</button> : ""}
        {deleteMode ? (
          <button
            style={{ float: "right", marginBottom: "20px" }}
            onClick={() => setDeleteMode(false)}
          >
            Cancel
          </button>
        ) : (
          <button
            style={{ float: "right", marginBottom: "20px" }}
            onClick={() => setDeleteMode(true)}
          >
            Delete
          </button>
        )}
        <div>
          <div>
            {changesSaved ? (
              <div style={{ color: "green" }}>All changes saved</div>
            ) : (
              <div style={{ color: "orange" }}>Unsaved changes</div>
            )}
          </div>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label style={{ display: "block", marginBottom: "8px" }}>Title</label>
          <input
            type="text"
            value={updatedItemTitle}
            onChange={onTitleChange}
            placeholder="Title"
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label style={{ display: "block", marginBottom: "8px" }}>Link</label>
          <input
            type="text"
            value={updatedItemLink}
            onChange={onLinkChange}
            placeholder="Link"
          />
        </div>
        <hr />
        <div>
          <p>Image</p>
          <div>
            <div style={{ marginBottom: "20px" }}>
              <img
                className="list-item-image-admin"
                src={itemImageUrl}
                alt={itemImageAltText}
              />
              <input
                style={{ width: "100%" }}
                type="text"
                value={updatedItemImageUrl}
                onChange={onImageUrlChange}
                placeholder="Image URL"
              />
              <textarea
                style={{ display: "block" }}
                value={updatedItemImageAltText}
                onChange={onAltTextChange}
                placeholder="Image alt text"
              />
            </div>
          </div>
        </div>
        <button onClick={onSave}>Save</button>
      </div>
    </>
  );
};
