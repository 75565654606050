import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./PageBlocks/Footer";
import Header from "./PageBlocks/Header";

const LayoutPublic = () => {
  return (
    <div
      className="vertical-layout"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <div className="main-content">
        <Outlet />
      </div>
      <div
        className="footer"
      >
        <Footer />
      </div>
    </div>
  );
};

export default LayoutPublic;
