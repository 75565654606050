import React, { useContext, useEffect, useState } from "react";

import Gallery from "../PageBlocks/ImageGallery";
import { GalleryImagesContext } from "../../App";
import GridPage from "./GridPage";
import ListPage from "./ListPage";
import PageFetcher from "../PageBlocks/StandardPageFetcher";

const PublicPage = ({ page }) => {
  const { pageType } = page;
  const imagesRaw = useContext(GalleryImagesContext);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const images = imagesRaw.map((image) => {
      const { imageAltText, imageUrl } = image;
      return {
        original: imageUrl,
        originalAlt: imageAltText,
      };
    });
    setImages(images);
  }, [imagesRaw]);

  return (
    <>
      <div className="public-wrapper">
        {pageType === "standard" ? (
          <StandardPage page={page} images={images} />
        ) : (
          ""
        )}
        {pageType === "list" ? <ListPage page={page} /> : ""}
        {pageType === "grid" ? <GridPage page={page} /> : ""}
        {pageType === "standard-hidden" ? (
          <StandardHiddenPage page={page} />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default PublicPage;

const StandardPage = ({ page, images }) => {
  const { id } = page;
  return (
    <>
      <div className="standard-page-layout">
        <div className="public-main-content">
          <PageFetcher pageId={id} />
        </div>
        <div className="public-main-image-gallery">
          <Gallery images={images} />
        </div>
      </div>
    </>
  );
};

const StandardHiddenPage = ({ page, images }) => {
  const { id } = page;
  return <PageFetcher pageId={id} />;
};
