import React, { useEffect, useState } from "react";
import { authRequest } from "../../../utils/http";
import Booking from "./Booking";
import BookingControl from "./BookingControl";
import convertStatusToUserStatus from "./convertStatusToUserStatus";

const TodaysBookings = () => {
    const [todaysDate, setTodaysDate] = useState('');
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        const getPageContent = async () => {
            const { status, data } = await authRequest("/bookings/today", "GET");
            if (status === 200) {
                const { todayFormatted, bookings } = data;
                if (bookings) {
                    const verifiedBookings = bookings.filter(
                        (booking) => booking.status === "verified" || booking.status === "approved" || booking.status === "denied"
                    );
                    setBookings(verifiedBookings);
                }
                setTodaysDate(todayFormatted);
            } else {
                console.error("An error has occurred");
            }
        };
        getPageContent();
    }, []);

    return (
        <>
            <h2 className="admin-message" style={{ marginTop: "40px" }}>Today - {todaysDate}</h2>
            {bookings.length === 0 ? <div style={{ marginTop: "10px" }}>There are no bookings today</div> : bookings.map(booking => {
                const adminUserStatus = convertStatusToUserStatus(booking.status);
                return <Booking key={booking.id} booking={booking}><BookingControl booking={booking} adminUserStatus={adminUserStatus} convertStatusToUserStatus={convertStatusToUserStatus} /></Booking>
            })}
        </>
    );
};

export default TodaysBookings;