import React from 'react';
import ImageGallery from 'react-image-gallery';

const Gallery = ({ images }) => {
    if (!images) {
        return;
    }
    return (
        <>
            <div className="image-gallery-background">
                <div className="image-gallery-above-inner"></div>
                <ImageGallery items={images} showBullets={true} showNav={false} showFullscreenButton={false} showPlayButton={false} autoPlay={true} />
            </div>
        </>
    );
};

export default Gallery;