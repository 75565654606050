import React, { useEffect, useState } from "react";
import { authRequest, openRequest } from "../../utils/http";

const AdminEditGalleryImages = () => {
    const [loading, setLoading] = useState(true);
    const [galleryImages, setGalleryImages] = useState([]);
    const [newItemShowing, setNewItemShowing] = useState(false);

    useEffect(() => {
        const getImages = async () => {
            const { status, data } = await openRequest(`/gallery-images`, "GET");
            if (status === 200) {
                setGalleryImages(data);
                setLoading(false);
            } else {
                // API ERROR
            }
        };
        getImages();
    }, []);

    const clearNewItemAndSetGalleryImages = (newGalleryImages) => {
        setNewItemShowing(false);
        setGalleryImages(newGalleryImages);
    }

    return loading ? (
        "Loading Editor..."
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div style={{ width: "80%", marginTop: "20px" }}>
                {galleryImages.map((item) => {
                    return (
                        <ListItemEditor key={item.id} item={item} setGalleryImages={setGalleryImages} />
                    );
                })}
                {newItemShowing ? <ListItemEditor isNewItem={true} item={{}} setGalleryImages={clearNewItemAndSetGalleryImages} /> : <button onClick={() => setNewItemShowing(true)}>Add New Item</button>}
            </div>
        </div>
    );
};

export default AdminEditGalleryImages;

const ListItemEditor = ({ item, isNewItem, setGalleryImages }) => {
    const { imageOrdering, imageUrl, imageAltText } = item;
    const [changesSaved, setChangesSaved] = useState(true);
    const [updatedItemOrdering, setUpdatedItemOrdering] = useState(imageOrdering || "");
    const [updatedItemImageUrl, setUpdatedItemImageUrl] = useState(imageUrl || "");
    const [updatedItemImageAltText, setUpdatedItemImageAltText] = useState(imageAltText || "");

    const onSave = async () => {
        const saveGalleryImage = async () => {
            const res = await authRequest("/gallery-images", isNewItem ? "POST" : "PUT", {
                id: isNewItem ? null : item.id,
                imageOrdering: updatedItemOrdering || 0,
                imageUrl: updatedItemImageUrl,
                imageAltText: updatedItemImageAltText,
            });
            const { status, data } = res;
            if (status === 200 && data) {
                console.log(data);
                setChangesSaved(true);
                setGalleryImages(data);
            } else {
                // ERROR
            }
        };
        saveGalleryImage();
    };

    const onOrderingChange = (event) => {
        setChangesSaved(false);
        setUpdatedItemOrdering(event.target.value);
    };

    const onAltTextChange = (event) => {
        setChangesSaved(false);
        setUpdatedItemImageAltText(event.target.value);
    };

    const onImageUrlChange = (event) => {
        setChangesSaved(false);
        setUpdatedItemImageUrl(event.target.value);
    };

    return (
        <>
            <div style={{ border: "solid 2px black", padding: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {changesSaved ? (
                            <div style={{ color: "green" }}>All changes saved</div>
                        ) : (
                            <div style={{ color: "orange" }}>Unsaved changes</div>
                        )}
                    </div>
                </div>
                <div style={{ paddingLeft: "40px" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <img className="gallery-image-in-admin" src={updatedItemImageUrl} alt={updatedItemImageUrl} />
                        <input style={{ width: "100%" }} type="text" value={updatedItemImageUrl} onChange={onImageUrlChange} placeholder="Image URL" />
                        <textarea style={{ display: "block" }} value={updatedItemImageAltText} onChange={onAltTextChange} placeholder="Image alt text" />
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <p>Item Order</p>
                        <input type="number" value={updatedItemOrdering} onChange={onOrderingChange} />
                    </div>
                </div>
                <button className={``} onClick={onSave}>
                    Save
                </button>
            </div >
        </>
    );
}