import React from "react";
import PageRenderer from "../PageRenderer";

const ConditionsCheck = ({ setStep, allRenderData }) => {
    const { conditionsCheck } = allRenderData || {};
    const { content } = conditionsCheck || {};
    return (
        <>
            <div className="form-content"><PageRenderer renderData={content} /></div>
            <button onClick={() => setStep((prev) => prev + 1)}>Agree {'&'} Proceed</button>
        </>
    );
};

export default ConditionsCheck;