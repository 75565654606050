import React, { useEffect, useState } from "react";
import { authRequest } from "../../../utils/http";
import Booking from "./Booking";
import BookingControl from "./BookingControl";
import convertStatusToUserStatus from "./convertStatusToUserStatus";

const WeeklyCalendar = () => {
    const [weekOffset, setWeekOffset] = useState(0);
    const [weekBookingsDays, setWeekBookingsDays] = useState([]);

    useEffect(() => {
        const getWeekBookings = async () => {
            const { status, data } = await authRequest(`/bookings/week?offset=${weekOffset}`, "GET");
            if (status === 200) {
                const { weekBookingsDays } = data;
                if (weekBookingsDays) {
                    setWeekBookingsDays(weekBookingsDays);
                }
            } else {
                console.error("An error has occurred");
            }
        };
        getWeekBookings();
    }, [weekOffset]);

    return (
        <div className="calender_container">
            <div style={{ display: "flex", justifyContent: 'space-around' }}>
                <button className="button-styles-remove" onClick={() => setWeekOffset(weekOffset - 1)}>{"<"}</button>
                <button className="button-styles-remove" onClick={() => setWeekOffset(weekOffset + 1)}>{">"}</button>
            </div>
            <ul className="bookings-list">
                {
                    weekBookingsDays.map((day) => {
                        return (
                            <div key={day.formattedDate}>
                                <h2 className="admin-message">{day.formattedDate}</h2>
                                {day.bookings.length === 0 ? <div>...</div> : day.bookings.map((booking) => {
                                    const adminUserStatus = convertStatusToUserStatus(booking.status);
                                    return <Booking
                                        key={booking.id}
                                        booking={booking}
                                        convertStatusToUserStatus={convertStatusToUserStatus}
                                    >
                                        <BookingControl adminUserStatus={adminUserStatus} id={booking.id} convertStatusToUserStatus={convertStatusToUserStatus} booking={booking} />
                                    </Booking>
                                })}
                            </div>
                        );
                    }
                    )
                }
            </ul>
        </div>
    );
};

export default WeeklyCalendar;
