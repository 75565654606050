import React from "react";
import { authRequest } from "../../../utils/http";

const DownloadBookings = () => {
    return (
        <>
            <p style={{ marginTop: "40px" }}>Download a CSV file with a full history of bookings.</p>
            <DownloadAllBookings />
        </>
    );
}

const DownloadAllBookings = () => {
    const onDownload = async () => {
        const res = await authRequest("/bookings/download", "POST", {}, true);
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        const fileName = "bookings.csv";
        fileLink.setAttribute('download', fileName);
        fileLink.setAttribute('target', '_blank');
        document.body.appendChild(fileLink);
        fileLink.click();
        fileLink.remove();
    };

    return (
        <>
            <button className="button-styles-remove" onClick={onDownload}>Download All</button>
        </>
    );
};

export default DownloadBookings;