import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { openRequest } from "./utils/http";

import LayoutPublic from "./components/LayoutPublic";
import LayoutAdmin from "./components/LayoutAdmin";

import BookingPage from "./components/PublicPages/BookingPage";
import LoginPage from "./components/PublicPages/LoginPage";

import AdminBookingsSettings from "./components/AdminPages/AdminBookingsSettings";
import Verify from "./components/Verify";
import ResetPassword from "./components/ResetPassword";
import AdminMenuPage from "./components/AdminPages/AdminMenuPage";
import AdminBookingsManage from "./components/AdminPages/AdminBookingsManage";
import AdminCreatePage from "./components/AdminPages/AdminCreatePage";

import PublicPage from "./components/PublicPages/PublicPage";
import NotFoundPage from "./components/PublicPages/NotFoundPage";
import AdminEditPageChoice from "./components/AdminPages/AdminEditPageChoice";
import AdminDeletePage from "./components/AdminPages/AdminDeletePage";
import AdminEditFooter from "./components/AdminPages/AdminEditFooter";
import AdminEditHeader from "./components/AdminPages/AdminEditHeader";
import AdminEditGalleryImages from "./components/AdminPages/AdminEditGalleryImages";
import AdminOrderPages from "./components/AdminPages/AdminOrderPages";
import AdminAdminUsers from "./components/AdminPages/AdminAdminUsers";

export const PagesContext = createContext(null);
export const FooterContext = createContext(null);
export const HeaderContext = createContext(null);
export const GalleryImagesContext = createContext(null);

function App() {
  const [pagesLoading, setPagesLoading] = useState(true);
  const [footerLoading, setFooterLoading] = useState(true);
  const [headerLoading, setHeaderLoading] = useState(true);
  const [galleryImagesLoading, setGalleryImagesLoading] = useState(true);
  const [pages, setPages] = useState(null);
  const [footerContent, setFooterContent] = useState(null);
  const [headerContent, setHeaderContent] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);

  const getPagesColors = (pages) => {
    const colors = ["#BF2546", "#F3AA42", "#92B740", "#5080AB", "#AE2E75", "#242868"];
    const defaultOrange = "#e46137";
    const pagesWithColors = pages.map((page, index) => {
      if (page.pageSlug !== "book") {
        page.color = colors[index] ? colors[index] : defaultOrange;
      } else {
        page.color = defaultOrange;
      }
      return page;
    });
    return pagesWithColors;
  }

  useEffect(() => {
    const getPages = async () => {
      const { status, data } = await openRequest('/pages', "GET");
      if (status === 200) {
        const pagesWithColors = getPagesColors(data);
        setPages(pagesWithColors);
        setPagesLoading(false);
      } else {
        // error handling
      }
    };
    getPages();
  }, []);

  useEffect(() => {
    const getFooterContent = async () => {
      const { status, data } = await openRequest(`/footer`, "GET");
      if (status === 200) {
        setFooterContent(data);
        setFooterLoading(false);
      } else {
        // API ERROR
      }
    }
    getFooterContent();
  }, []);

  useEffect(() => {
    const getHeaderContent = async () => {
      const { status, data } = await openRequest(`/header`, "GET");
      if (status === 200) {
        setHeaderContent(data);
        setHeaderLoading(false);
      } else {
        // API ERROR
      }
    }
    getHeaderContent();
  }, []);

  useEffect(() => {
    const getGalleryImages = async () => {
      const { status, data } = await openRequest('/gallery-images', "GET");
      if (status === 200) {
        const galleryImages = getPagesColors(data);
        setGalleryImages(galleryImages);
        setGalleryImagesLoading(false);
      } else {
        // error handling
      }
    };
    getGalleryImages();
  }, []);

  return (
    <PagesContext.Provider value={pages}>
      <FooterContext.Provider value={footerContent}>
        <HeaderContext.Provider value={headerContent}>
          <GalleryImagesContext.Provider value={galleryImages}>
            <Router>
              {pagesLoading || footerLoading || headerLoading || galleryImagesLoading ? <div>Loading...</div> :
                <Routes>
                  <Route path="/" element={<LayoutPublic />}>
                    {console.log(pages)}
                    {
                      pages.map((page) => {
                        const { pageSlug } = page;
                        if (pageSlug === "home") {
                          return <Route key={page.id} index element={<PublicPage key={page.id} page={page} />} />;
                        }
                        return (
                          <Route
                            key={page.id}
                            path={page.pageSlug}
                            element={pageSlug !== "book" ? <PublicPage key={page.id} page={page} /> : <BookingPage key={page.id} />} />
                        );
                      })
                    }
                    <Route path="verify-booking" element={<Verify />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route path="login" element={<LoginPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                  <Route path="/admin" element={<LayoutAdmin />}>
                    <Route index element={<AdminMenuPage />} />
                    {pages.map((page) => {
                      return (
                        <Route
                          key={page.id + "_admin_route"}
                          path={page.pageSlug}
                          element={<AdminEditPageChoice key={page.id} page={page} />} />
                      )
                    })}
                    <Route path="manage-bookings" element={<AdminBookingsManage />} />
                    <Route path="bookings-settings" element={<AdminBookingsSettings />} />
                    <Route path="create-page" element={<AdminCreatePage />} />
                    <Route path="delete-page" element={<AdminDeletePage />} />
                    <Route path="edit-footer" element={<AdminEditFooter />} />
                    <Route path="edit-header" element={<AdminEditHeader />} />
                    <Route path="edit-gallery-images" element={<AdminEditGalleryImages />} />
                    <Route path="order-pages" element={<AdminOrderPages pages={pages} />} />
                    <Route path="manage-admin-users" element={<AdminAdminUsers />} />
                  </Route>
                </Routes>}
            </Router>
          </GalleryImagesContext.Provider>
        </HeaderContext.Provider>
      </FooterContext.Provider>
    </PagesContext.Provider >
  );
}

export default App;
