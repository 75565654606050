import React, { useState } from "react";

import RichTextEditor from "./AdminUtils/RichTextEditor";
import { authRequest } from "../../utils/http";
import slugify from "slugify";

const AdminCreatePage = () => {
  const [pageSlug, setPageSlug] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [pageType, setPageType] = useState("standard");
  const [editorValue, setEditorValue] = useState([
    {
      type: "paragraph",
      children: [{ text: "Your new page content goes here!" }],
    },
  ]);

  const onSave = async () => {
    const savePageContent = async () => {
      const res = await authRequest("/pages", "POST", {
        pageSlug: pageSlug,
        pageTitle: pageTitle,
        pageContent: editorValue,
        pageType: pageType,
      });
      if (res.status === 200) {
        window.location.href = "/admin";
      } else {
        // ERROR
      }
    };
    savePageContent();
  };

  const onEditorUpdate = (value) => {
    setEditorValue(value);
  };

  const handleTitleChange = (event) => {
    setPageTitle(event.target.value);
    setPageSlug(slugify(event.target.value, { strict: true, lower: true }));
  };

  return (
    <>
      <h2>Create A Page</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "80%", marginTop: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <h3>Page Title In Nav Bar</h3>
            <input
              type="text"
              name="pageTitle"
              value={pageTitle}
              onChange={handleTitleChange}
            />
          </div>
          <div style={{ display: "flex", marginBottom: "20px" }}>
            <span>
              <b>Page Slug:</b>
              {" /"}
            </span>
            <span>{pageSlug}</span>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <p>
              The page types are:
              <ul>
                <li>
                  <b>Standard Page</b>: A page with one free text area. The page
                  will be shown in the header menu.
                </li>
                <li>
                  <b>Standard Hidden</b>: A page with one free text area. The
                  page will <b>not</b> be shown in the header menu.
                </li>
                <li>
                  <b>List Page</b>: A page with a list of items. Each item
                  should have an associated image. The page will be shown in the
                  header menu.
                </li>
                <li>
                  <b>Grid Page</b>: A page with a grid of items. Each item
                  should have an associated image. The page will be shown in the
                  header menu.
                </li>
              </ul>
            </p>
            <h3>Page Type</h3>
            <select
              name="pageType"
              value={pageType}
              onChange={(event) => setPageType(event.target.value)}
            >
              <option value="standard">Stardard Page</option>
              <option value="standard-hidden">Stardard Hidden</option>
              <option value="list">List Page</option>
              <option value="grid">Grid Page</option>
            </select>
          </div>
          {["list", "grid"].includes(pageType) ? (
            <div style={{ marginBottom: "20px" }}>
              <p>
                Save your new list page now, and then choose your page in the
                Edit Pages menu to add items
              </p>
            </div>
          ) : (
            <RichTextEditor
              editorValue={editorValue}
              setUpdatedValue={onEditorUpdate}
            />
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={{ marginBottom: "20px" }}
              className={``}
              onClick={onSave}
            >
              Save Page
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCreatePage;

/* 

<h2>Create A Page</h2>
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >

            <div style={{ width: "80%", marginTop: "20px" }}>
                <div style={{ marginBottom: "20px" }}>
                    <h3>Page Title In Nav Bar</h3>
                    <input
                        type="text"
                        name="pageTitle"
                        value={pageTitle}
                        onChange={handleTitleChange}
                    />
                </div>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                    <span><b>Page Slug:</b>{" " + "/"}</span><span>{pageSlug}</span>
                </div>
                <div style={{ marginBottom: "20px" }}>
                    <h3>Page Type</h3>
                    <select
                        name="pageType"
                        value={pageType}
                        onChange={(event) => setPageType(event.target.value)}
                    >
                        <option value="standard">Stardard Page</option>
                        <option value="list">List Page</option>
                    </select>
                    <RichTextEditor
                        editorValue={editorValue}
                        setUpdatedValue={onEditorUpdate}
                    />
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <button style={{ marginBottom: "20px" }} className={``} onClick={onSave}>
                            Save Page
                        </button>
                    </div>
                </div>
            </div>

*/
