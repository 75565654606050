import React, { useState, useContext } from "react";
import { authRequest } from "../../utils/http";
import RichTextEditor from "./AdminUtils/RichTextEditor";
import { FooterContext } from "../../App";

const AdminEditFooter = () => {
    const footerContent = useContext(FooterContext);
    const [editorValue, setEditorValue] = useState(footerContent);
    const [changesSaved, setChangesSaved] = useState(true);

    const onSave = async () => {
        const savePageContent = async () => {
            const res = await authRequest("/footer", "PUT", {
                footerContent: editorValue,
            });
            if (res.status === 200) {
                setChangesSaved(true);
            } else {
                // ERROR
            }
        };
        savePageContent();
    };

    const onEditorUpdate = (value) => {
        setChangesSaved(false);
        setEditorValue(value);
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div style={{ width: "80%", marginTop: "20px" }}>
                <h1 style={{ marginBottom: "20px" }}>Edit Footer Information</h1>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {changesSaved ? (
                            <div style={{ color: "green" }}>All changes saved</div>
                        ) : (
                            <div style={{ color: "orange" }}>Unsaved changes</div>
                        )}
                    </div>
                    <button className={``} onClick={onSave}>
                        Save
                    </button>
                </div>
                <RichTextEditor
                    editorValue={editorValue}
                    setUpdatedValue={onEditorUpdate}
                />
            </div>
        </div>
    );
};

export default AdminEditFooter;