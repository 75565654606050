import React, { useEffect, useState } from "react";
import { openRequest } from "../../utils/http";
import PageRenderer from "./PageRenderer";

const StandardPageFetcher = ({ pageId }) => {
  const [renderData, setRenderData] = useState(null);

  useEffect(() => {
    const getPageContent = async () => {
      const { status, data } = await openRequest(`/pages/${pageId}`, "GET");
      if (status === 200) {
        setRenderData(data.pageContent);
      } else {
        // API ERROR
      }
    };
    getPageContent();
  }, [pageId]);

  return (
    <>
      <PageRenderer renderData={renderData} />
    </>
  );
};

export default StandardPageFetcher;
